import {
  Create,
  BooleanInput,
  ReferenceInput,
  SimpleForm,
  NumberInput,
  useCreate,
  useNotify,
  useRedirect,
} from 'react-admin'

import { VariantsValueInput } from '@/components/VariantsValueInput'
import { EVariant, TValues } from './types'
import { valuesMapper } from './consts'
import { VARIANTS } from '@/services/api/routes'

export const VariantsCreate = () => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()
  const handleSubmit = (values: TValues) => {
    create(
      'variants',
      { data: valuesMapper(values) },
      {
        onSuccess: () => {
          notify('Variant created', {
            type: 'success',
          })
          redirect(VARIANTS())
        },
        onError: (error) => {
          notify(`${error}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Create title="Create new Variant" redirect="list">
      <SimpleForm onSubmit={handleSubmit} sanitizeEmptyValues>
        <BooleanInput source={EVariant.IsActive} fullWidth />
        <BooleanInput source={EVariant.IsReserved} fullWidth />
        <NumberInput
          source={EVariant.Order}
          label="Order"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Order must be an integer')}
          fullWidth
        />
        <ReferenceInput
          sort={{ field: 'enName', order: 'ASC' }}
          source={EVariant.ProductId}
          reference={'products'}
          perPage={1000}
        >
          <VariantsValueInput isCreate />
        </ReferenceInput>

        <BooleanInput source={EVariant.BnplActive} fullWidth />
        <NumberInput
          source={EVariant.BnplPercentage}
          label="Commission"
          validate={(value) => (Number.isInteger(value) || value === null ? undefined : 'Order must be an integer')}
          fullWidth
        />
      </SimpleForm>
    </Create>
  )
}
