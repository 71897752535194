export enum EPromocode {
  Id = 'id',
  Code = 'code',
  Value = 'value',
  ValueType = 'valueType',
  MaxUsage = 'maxUsage',
  maxRedemptionsPerUser = 'maxRedemptionsPerUser',
  ExpiryDate = 'expiryDate',
  ConsumerPhones = 'consumerPhones',
  MaxRegisterDate = 'maxRegisterDate',
  PromoCodableType = 'promoCodableType',
  PromoCodableIds = 'promoCodableIds',
  NeverActiveUsers = 'neverActiveUsers',
  IsActive = 'isActive',
  IsPayNow = 'isPayNow',
  IsPayLater = 'isPayLater',
  CreatedAt = 'createdAt',
  CodeLike = 'codeLike',
  Platform = 'platform',
  maxDiscount = 'maxDiscount',
}

export enum EValueType {
  FIXED = 'FIXED',
  RATE = 'RATE',
}

export enum EPromoCodeType {
  BRAND = 'BRAND',
  PRODUCT = 'PRODUCT',
  VARIANT = 'VARIANT',
}

export enum EPlatformType {
  CONSUMER_APP = 'CONSUMER_APP',
  QI_SERVICES_APP = 'QI_SERVICES_APP',
  SUPER_QI_APP = 'SUPER_QI_APP',
}

type TType = [EValueType.FIXED] | [EValueType.RATE]
type TPromoCodeType = [EPromoCodeType.BRAND] | [EPromoCodeType.PRODUCT] | [EPromoCodeType.VARIANT]
type TPlatformType = [EPlatformType.CONSUMER_APP] | [EPlatformType.QI_SERVICES_APP] | [EPlatformType.SUPER_QI_APP]

export type TPromocode = {
  [EPromocode.Id]: number
  [EPromocode.Code]: string
  [EPromocode.Value]: number
  [EPromocode.ValueType]: TType
  [EPromocode.MaxUsage]: number
  [EPromocode.maxRedemptionsPerUser]: number
  [EPromocode.maxDiscount]: number
  [EPromocode.ExpiryDate]: Date
  [EPromocode.ConsumerPhones]: string[]
  [EPromocode.MaxRegisterDate]: Date
  [EPromocode.PromoCodableType]: TPromoCodeType
  [EPromocode.PromoCodableIds]: number[]
  [EPromocode.IsActive]: boolean
  [EPromocode.CreatedAt]: string
  [EPromocode.NeverActiveUsers]: boolean
  [EPromocode.Platform]: TPlatformType
}
