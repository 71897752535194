import {
  List,
  Datagrid,
  NumberField,
  TextField,
  BooleanField,
  TopToolbar,
  Button,
  CreateButton,
  Confirm,
  BulkExportButton,
  ReferenceInput,
  SelectInput,
  FilterButton,
  BulkActionProps,
  BulkDeleteButton,
  useRefresh,
  WithRecord,
} from 'react-admin'
import { EValue, EVariant } from '@/modules/Variants/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import { Fragment, useState } from 'react'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import { MarketplaceVariantbulkUpload } from '@/components/MarketplaceVariantBulkUpload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { activeStatusList } from '@/modules/Brands/types'
import { ECostChange } from '@/modules/CostChanges/types'
import { BulkStatusActionButton } from '@/components/BulkStatusActionButton'
import DateTimeField from '@/components/DateTimeField'
import { TMarketplaceVariant } from '@/modules/Marketplace/types'

export const MarketplaceVariantsList = () => {
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [brandId, setBrandId] = useState<null | number>(() => {
    // Initialize from localStorage on mount
    const saved = localStorage.getItem('marketplace-variants-brand-id')
    return saved ? JSON.parse(saved) : null
  })

  const handleBrandChange = (value: any) => {
    setBrandId(value)
    localStorage.setItem('marketplace-variants-brand-id', JSON.stringify(value))
  }

  const handleCloseImageModal = () => setImageModalOpen(false)
  const handleConfirmImageModal = () => setImageModalOpen(false)

  const refresh = useRefresh()

  const MarketplaceVariantsActions = () => (
    <TopToolbar>
      <FilterButton />
      <Can I="createMarketplaceVariant" a="marketplace-variants">
        <CreateButton />
      </Can>
      <Can I="imageBulkUpdate" a="variants">
        <Button onClick={() => setImageModalOpen(true)} label="Bulk Upload">
          <FileUploadIcon />
        </Button>
      </Can>
      <BulkExportButton />
    </TopToolbar>
  )

  const marketplaceVariantsFilters = [
    <ReferenceInput
      source={'marketplaceId'}
      reference={'marketplace'}
      perPage={1000}
      key={'marketplaceId'}
      label="Marketplace"
      sort={{ field: 'name', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput optionText="name" label="Marketplace" key={'marketplaceId'} />
    </ReferenceInput>,

    <ReferenceInput
      source={'brandId'}
      reference={'brands'}
      perPage={1000}
      key={'brandId'}
      label="Brand Name"
      sort={{ field: 'enName', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput onChange={(e) => handleBrandChange(e.target.value)} optionText="enName" label="Brand Name" />
    </ReferenceInput>,

    <ReferenceInput
      source={'productId'}
      reference={'products'}
      perPage={1000}
      key={'productId'}
      sort={{ field: 'enName', order: 'ASC' }}
      label="Product"
      filter={brandId ? { brandId } : undefined}
    >
      <SelectInput
        optionText={(record) => ` ${brandId ? `${record?.brand?.enName} | ` : ''}${record.enName}`}
        label="Product"
      />
    </ReferenceInput>,

    <ReferenceInput source={ECostChange.VariantId} reference="variants" perPage={1000} key={'variantId'}>
      <ReferenceInput source={'variantId'} reference={'variants'} key={'enName'} label="Variant" perPage={1000}>
        <SelectInput
          optionText={(record) =>
            `#${record[EVariant.Id]} - ${record[EVariant.Value]
              .map((v: { [EValue.EnName]: string; [EValue.EnValue]: string }) => v[EValue.EnName] || v[EValue.EnValue])
              .join(' | ')}`
          }
          label="Variants"
        />
      </ReferenceInput>
    </ReferenceInput>,
    <SelectInput source="isActive" label="Active Status" key="isActive" choices={activeStatusList} />,
  ]

  const MarketplaceVariantBulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
      <Can I="bulkUpdate" a="products">
        <BulkStatusActionButton
          resource="products"
          selectedIds={props.selectedIds}
          url="/dashboard/marketplace-variants/bulk-update"
          actionKey="isActive"
          options={[
            { value: true, label: 'Activate' },
            { value: false, label: 'Deactivate' },
          ]}
        />
      </Can>
      <BulkExportButton />
      <Can I="deleteMarketplaceVariant" a="marketplace-variant">
        <BulkDeleteButton mutationOptions={{ onMutate: refresh }} />
      </Can>
    </Fragment>
  )

  return (
    <Can I="getMarketplaceVariants" a="marketplace-variants">
      <List filters={marketplaceVariantsFilters} actions={<MarketplaceVariantsActions />} title="Marketplace Variants">
        <Confirm
          isOpen={imageModalOpen}
          title="Marketplace bulk Upload"
          content={<MarketplaceVariantbulkUpload handleClose={handleCloseImageModal} />}
          cancel=""
          confirm="Close"
          ConfirmIcon={() => <ErrorOutlinedIcon />}
          CancelIcon={() => null}
          onConfirm={handleConfirmImageModal}
          onClose={handleCloseImageModal}
        />

        <Datagrid
          bulkActionButtons={<MarketplaceVariantBulkActionButtons />}
          rowClick={ability.can('updateMarketplaceVariant', 'marketplace-variants') ? 'edit' : false}
        >
          <NumberField source={EVariant.Id} textAlign="left" />
          <TextField source="marketplace.name" label="Marketplace" sortable={false} />
          <TextField source="price" label="Price" sortable={false} />
          <WithRecord
            label="Variant"
            render={(record: TMarketplaceVariant) => {
              return record?.variant?.value?.[0]?.enName || record?.variant?.value?.[0]?.arValue
            }}
          />

          <TextField source="variant.id" label="Variant ID" sortable={false} />
          <TextField source="variant.product.enName" label="Product" sortable={false} />
          <BooleanField source="active" sortable={false} />
          <TextField source="variant.product.brand.enName" label="Brand" sortable={false} />
          <DateTimeField source={EVariant.CreatedAt} />
          <DateTimeField source={EVariant.UpdatedAt} />
        </Datagrid>
      </List>
    </Can>
  )
}
