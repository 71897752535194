import { NO_WHITESPACE } from '@/consts/regexp'
import { Box } from '@mui/material'
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  regex,
  required,
} from 'react-admin'
import {
  choices,
  optionText,
  optionTextBrandsOrProducts,
  platformChoises,
  promoCodeChoices,
  validatePhone,
} from './consts'
import { EPlatformType, EPromoCodeType, EPromocode } from './types'
import { useState } from 'react'

const promoCodeTypeToReference = {
  BRAND: 'brands',
  PRODUCT: 'products',
  VARIANT: 'variants',
}

export const PromocodesCreate = () => {
  const [selectedPromoCodeType, setSelectedPromoCodeType] = useState('brands')
  const [typeIds, setTypeIds] = useState([])

  const handlePromoCodeTypeChange = (event: any) => {
    const selectedType: EPromoCodeType = event.target.value

    setSelectedPromoCodeType(promoCodeTypeToReference[selectedType])
  }

  const handleTypeIdsChange = (value: any) => {
    setTypeIds(value)
  }

  const isPromoCodeTypeDisabled = typeIds.length > 0

  const promoCodeTypeOptionText = (record: any) => {
    if (selectedPromoCodeType === 'brands' || selectedPromoCodeType === 'products')
      return optionTextBrandsOrProducts(record)
    if (selectedPromoCodeType === 'variants') return optionText(record)
  }

  return (
    <Create title="Create new Promocode" redirect="list">
      <SimpleForm sanitizeEmptyValues>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <TextInput
            validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
            source={EPromocode.Code}
            fullWidth
            required
          />
          <NumberInput source={EPromocode.Value} fullWidth required min={0} />
          <SelectInput
            source={EPromocode.ValueType}
            choices={choices}
            validate={required()}
            optionValue="name"
            fullWidth
          />
          <NumberInput source={EPromocode.MaxUsage} fullWidth required min={0} />
          <NumberInput source={EPromocode.maxRedemptionsPerUser} fullWidth required min={0} />
          <NumberInput source={EPromocode.maxDiscount} fullWidth />

          <DateTimeInput source={EPromocode.ExpiryDate} fullWidth required />
          <FormDataConsumer>
            {({ formData }) => (
              <ArrayInput source={EPromocode.ConsumerPhones}>
                <SimpleFormIterator disableClear disableAdd={formData[EPromocode.ConsumerPhones]?.length > 14}>
                  <TextInput validate={validatePhone} source="" sx={{ minWidth: '375px' }} placeholder="+xy..." />
                </SimpleFormIterator>
              </ArrayInput>
            )}
          </FormDataConsumer>
          <DateTimeInput source={EPromocode.MaxRegisterDate} fullWidth required />
          <BooleanInput source={EPromocode.IsActive} fullWidth />
          <BooleanInput source={EPromocode.NeverActiveUsers} fullWidth />

          <BooleanInput source={EPromocode.IsPayNow} fullWidth />
          <BooleanInput source={EPromocode.IsPayLater} fullWidth />

          <SelectInput
            source={EPromocode.PromoCodableType}
            choices={promoCodeChoices}
            validate={required()}
            optionValue="name"
            fullWidth
            onChange={handlePromoCodeTypeChange}
            defaultValue={EPromoCodeType.BRAND}
            disabled={isPromoCodeTypeDisabled}
          />
          <ReferenceArrayInput source={EPromocode.PromoCodableIds} reference={selectedPromoCodeType} perPage={1000}>
            <AutocompleteArrayInput optionText={promoCodeTypeOptionText} fullWidth onChange={handleTypeIdsChange} />
          </ReferenceArrayInput>
          <SelectInput
            source={EPromocode.Platform}
            choices={platformChoises}
            validate={required()}
            optionValue="name"
            fullWidth
            defaultValue={EPlatformType.CONSUMER_APP}
          />
        </Box>
      </SimpleForm>
    </Create>
  )
}
